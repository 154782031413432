var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{class:`
    pt-${_vm.props.pt ? _vm.props.pt : '0'} 
    pb-${_vm.props.pb ? _vm.props.pb : '0'}`,attrs:{"id":`${_vm.props.target}`,"color":_vm.props.color_background}},[(_vm.props)?_c('v-container',{staticClass:"container-padding",attrs:{"fluid":"","id":_vm.props.id}},[(_vm.props.headline && _vm.props.headline[_vm.language])?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.props.headline[_vm.language])}})])],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-sheet',{staticClass:"rounded-tl",attrs:{"elevation":"0","height":"100%","color":_vm.props.color_primary ? _vm.props.color_primary : 'background_primary'}},[(_vm.props.img_href_1)?_c('v-img',{staticClass:"rounded-tl",attrs:{"width":"100%","height":"100%","src":_vm.props.img_href_1}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-sheet',{staticClass:"pa-8",staticStyle:{"position":"relative"},attrs:{"elevation":"0","height":"100%","color":_vm.props.color_primary ? _vm.props.color_primary : 'background_primary'}},[(_vm.props.text_1_0 && _vm.props.text_1_0[_vm.language])?_c('span',{class:_vm.props.mode_primary == 'dark' ? 'white--text ' : '',domProps:{"innerHTML":_vm._s(_vm.props.text_1_0[_vm.language])}}):_vm._e()])],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('v-sheet',{staticClass:"pa-8 rounded-tr",staticStyle:{"position":"relative"},attrs:{"elevation":"0","height":"100%","color":_vm.props.color_secondary
              ? _vm.props.color_secondary
              : 'background_secondary'}},[(_vm.props.text_2_0 && _vm.props.text_2_0[_vm.language])?_c('span',{class:_vm.props.mode_secondary == 'dark' ? 'white--text ' : '',domProps:{"innerHTML":_vm._s(_vm.props.text_2_0[_vm.language])}}):_vm._e()])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('v-sheet',{staticClass:"pa-8",staticStyle:{"position":"relative"},attrs:{"elevation":"0","height":"100%","color":_vm.props.color_primary ? _vm.props.color_primary : 'background_primary'}},[(_vm.props.text_0_1 && _vm.props.text_0_1[_vm.language])?_c('span',{class:_vm.props.mode_primary == 'dark' ? 'white--text ' : '',domProps:{"innerHTML":_vm._s(_vm.props.text_0_1[_vm.language])}}):_vm._e()])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-sheet',{staticClass:"pa-8",staticStyle:{"position":"relative"},attrs:{"elevation":"0","height":"100%","color":_vm.props.color_secondary
              ? _vm.props.color_secondary
              : 'background_secondary'}},[(_vm.props.text_1_1 && _vm.props.text_1_1[_vm.language])?_c('span',{class:_vm.props.mode_secondary == 'dark' ? 'white--text ' : '',domProps:{"innerHTML":_vm._s(_vm.props.text_1_1[_vm.language])}}):_vm._e()])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-sheet',{attrs:{"elevation":"0","height":"100%","color":_vm.props.color_primary ? _vm.props.color_primary : 'background_primary'}},[(_vm.props.img_href_2)?_c('v-img',{attrs:{"width":"100%","height":"100%","src":_vm.props.img_href_2}}):_vm._e()],1)],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-sheet',{staticClass:"pa-8 rounded-bl",staticStyle:{"position":"relative"},attrs:{"elevation":"0","height":"100%","color":_vm.props.color_secondary
              ? _vm.props.color_secondary
              : 'background_secondary'}},[(_vm.props.text_0_2 && _vm.props.text_0_2[_vm.language])?_c('span',{class:_vm.props.mode_secondary == 'dark' ? 'white--text ' : '',domProps:{"innerHTML":_vm._s(_vm.props.text_0_2[_vm.language])}}):_vm._e()])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"4","xl":"4"}},[_c('v-sheet',{attrs:{"elevation":"0","height":"100%","color":_vm.props.color_primary ? _vm.props.color_primary : 'background_primary'}},[(_vm.props.img_href_3)?_c('v-img',{attrs:{"width":"100%","height":"100%","src":_vm.props.img_href_3}}):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('v-sheet',{staticClass:"pa-8 rounded-br",staticStyle:{"position":"relative"},attrs:{"elevation":"0","height":"100%","color":_vm.props.color_primary ? _vm.props.color_primary : 'background_primary'}},[(_vm.props.text_2_2 && _vm.props.text_2_2[_vm.language])?_c('span',{class:_vm.props.mode_primary == 'dark' ? 'white--text ' : '',domProps:{"innerHTML":_vm._s(_vm.props.text_2_2[_vm.language])}}):_vm._e()])],1)],1),(_vm.edit)?_c('v-dialog',{attrs:{"persistent":"","width":"100%"},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 black white--text"},[_vm._v(" Edit Component ")]),_c('v-card-text',{staticClass:"pt-5"},[_c('Core',{attrs:{"editProps":_vm.editProps,"name":"Core"},on:{"emitProps":_vm.setPreviewProps,"save":_vm.save,"cancel":_vm.cancel}}),_c('h2',{staticClass:"my-2 text-h4 black--text"},[_vm._v("Preview:")]),(_vm.previewProps)?_c('CompGrid3x3',{attrs:{"name":"CompGrid3x3","props":_vm.previewProps}}):_vm._e()],1),_c('v-divider')],1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }