var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectedTarifAndOptions)?_c('v-container',{attrs:{"fluid":""}},[_c('v-expansion-panels',[_c('v-expansion-panel',{staticClass:"rounded-lg"},[_c('v-expansion-panel-header',{staticClass:"pa-10",attrs:{"disable-icon-rotate":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-icon',{attrs:{"color":"black","medium":""}},[_vm._v(" mdi-chevron-down ")])]},proxy:true}],null,false,588448883)},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('h4',{staticClass:"mb-2 text-h4"},[_vm._v("Basistarif Friends")]),_c('p',[_vm._v("(Hausstrom)")])]),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4","lg":"4","xl":"4"}},[_c('div',{staticClass:"text-body-1"},[_c('p',{staticClass:"my-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Grundpreis:")]),_vm._v(" "+_vm._s(_vm.selectedTarifAndOptions.tarif.base_price .toFixed(2) .toString() .replace(".", ","))+" "+_vm._s(_vm.selectedTarifAndOptions.tarif.base_price_unit)+" ")]),_c('p',{staticClass:"my-1"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Arbeitspreis:")]),_vm._v(" "+_vm._s(_vm.selectedTarifAndOptions.tarif.working_price .toFixed(2) .toString() .replace(".", ","))+" "+_vm._s(_vm.selectedTarifAndOptions.tarif.working_price_unit)+" ")])])]),_c('v-col',{class:`mt-4 mr-2 ${
              _vm.$vuetify.breakpoint.smAndUp ? 'text-right' : ''
            }`,attrs:{"cols":"12","sm":"3","md":"3","lg":"3","xl":"3"}},[_c('p',{staticClass:"mr-2 text-body-1 font-weight-bold"},[_vm._v(" Vertrags"),_c('wbr'),_vm._v("informationen ")])])],1)],1),_c('v-expansion-panel-content',{staticClass:"pa-0 text-body-2",attrs:{"id":"TarifHeader"}},[_c('v-sheet',{staticClass:"pa-10 pb-16",attrs:{"color":"background_primary"}},[_c('h2',{class:`text-h5 mb-10 ${
              _vm.$vuetify.breakpoint.smAndUp ? 'px-9' : 'px-0'
            } mt-2`},[_vm._v(" Vertragsdetails ")]),_c('v-row',{class:`${_vm.$vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'}`},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"7"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Angegebener Verbrauch pro Jahr:")])]),_c('v-col',{staticClass:"text-right py-0"},[_c('span',[_c('nobr',[_vm._v(_vm._s(_vm.selectedTarifAndOptions.information.consumption)+" kWh")])],1)])],1),_c('v-row',{staticClass:"mt-10"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Kalkulierter Abschlag")])]),_c('v-col',{class:`text-h4 ${
                    _vm.$vuetify.breakpoint.mdAndUp ? 'text-right' : 'mt-2'
                  } py-0`,attrs:{"cols":"12","md":"6"}},[_c('span',[_c('nobr',[_vm._v(" "+_vm._s(_vm.selectedTarifAndOptions.tarif.price_per_month .toFixed(2) .toString() .replace(".", ","))+" "+_vm._s(_vm.selectedTarifAndOptions.tarif.price_per_month_unit)+" ")])],1)])],1)],1),_c('v-col',{attrs:{"offset-sm":"1","cols":"12","sm":"5"}},[_c('v-row',{class:`${_vm.$vuetify.breakpoint.smAndUp ? '' : 'mt-3'}`},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"9"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Vertragsdauer:")])]),_c('v-col',{staticClass:"text-right py-0",attrs:{"cols":"3"}},[_c('span',[_vm._v("31.12.2024")])])],1),_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"9"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Kündigungsfrist")])]),_c('v-col',{staticClass:"text-right py-0",attrs:{"cols":"3"}},[_c('span',[_vm._v("4 Wochen ")])])],1)],1)],1)],1),_c('v-sheet',{class:`rounded-b-lg ${
            _vm.$vuetify.breakpoint.smAndUp ? 'pa-10' : 'pa-4'
          } pb-16`},[_c('v-row',{class:`text-body-2 mt-5 ${
              _vm.$vuetify.breakpoint.smAndUp ? 'px-10' : 'px-0'
            }`},[_c('v-col',{class:`${_vm.$vuetify.breakpoint.smAndUp ? '' : 'pr-0'}`,attrs:{"cols":"9"}},[_c('h3',{staticClass:"text-h4"},[_vm._v("Summe für 12 Monate")])]),_c('v-col',{class:`text-right ${
                _vm.$vuetify.breakpoint.smAndUp ? '' : 'pl-0'
              }`,attrs:{"cols":"3"}},[_c('span',{staticClass:"text-h6"},[_c('nobr',[_vm._v(_vm._s(_vm.pricePerYear.toFixed(2).toString().replace(".", ","))+" €")])],1)])],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }