var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.props)?_c('v-img',{style:(_vm.props.mobileNoMargin & _vm.$vuetify.breakpoint.xs
      ? ``
      : `margin: ${
          _vm.props.margins ? (_vm.props.margins.mt ? _vm.props.margins.mt : 0) : 0
        }px 
                 ${
                   _vm.props.margins
                     ? _vm.props.margins.mr
                       ? _vm.props.margins.mr
                       : 0
                     : 0
                 }px 
                 ${
                   _vm.props.margins
                     ? _vm.props.margins.mb
                       ? _vm.props.margins.mb
                       : 0
                     : 0
                 }px 
                 ${
                   _vm.props.margins
                     ? _vm.props.margins.ml
                       ? _vm.props.margins.ml
                       : 0
                     : 0
                 }px`),attrs:{"aspect-ratio":_vm.props.aspect_ratio ? _vm.props.aspect_ratio : undefined,"src":_vm.props.url,"contain":_vm.props.contain ? _vm.props.contain : true,"height":_vm.props.height ? _vm.props.height : '100%'}}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }