var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{class:`
    pt-${_vm.props.pt ? _vm.props.pt : '0'} 
    pb-${_vm.props.pb ? _vm.props.pb : '0'}`,style:(`background-image: url(${_vm.props.img_href});background-position: center;`),attrs:{"id":`${_vm.props.target}`,"color":_vm.props.color_background}},[(_vm.props)?_c('v-container',{staticClass:"container-padding",attrs:{"fluid":""}},[_c('v-row',{staticClass:"py-6",attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6"}},[(
            !_vm.$vuetify.breakpoint.xs &&
            _vm.props.headline &&
            _vm.props.headline[_vm.language]
          )?_c('h1',{domProps:{"innerHTML":_vm._s(_vm.props.headline[_vm.language])}}):_vm._e(),(_vm.$vuetify.breakpoint.xs)?_c('center',[(_vm.props.headline && _vm.props.headline[_vm.language])?_c('h1',{staticClass:"white--text",domProps:{"innerHTML":_vm._s(_vm.props.headline[_vm.language])}}):_vm._e()]):_vm._e(),(_vm.props.image)?_c('v-img',{staticClass:"mt-15",attrs:{"max-width":"160","src":"https://www.veganstrom.com/wp-content/themes/veganstrom-theme-v2/img/oval-landschaft@2x.png"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"6","lg":"6","align":"right"}},[(_vm.props.button_text && _vm.props.button_text[_vm.language])?_c('v-btn',{staticClass:"px-6 py-7",attrs:{"color":"white","to":_vm.props.link},domProps:{"innerHTML":_vm._s(_vm.props.button_text[_vm.language])}}):_vm._e()],1)],1),(_vm.edit)?_c('v-dialog',{attrs:{"persistent":"","fullscreen":""},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 black white--text"},[_vm._v(" Edit Component ")]),_c('v-card-text',{staticClass:"pt-5"},[_c('Core',{attrs:{"editProps":_vm.editProps},on:{"emitProps":_vm.setPreviewProps,"save":_vm.save,"cancel":_vm.cancel}}),_c('h2',{staticClass:"my-2 text-h4 black--text"},[_vm._v("Preview:")]),(_vm.previewProps)?_c('CompBannerToTarif',{attrs:{"name":"CompBannerToTarif","props":_vm.previewProps}}):_vm._e()],1),_c('v-divider')],1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }