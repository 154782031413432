var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-sheet',{class:`
    pt-${_vm.props.pt ? _vm.props.pt : '0'} 
    pb-${_vm.props.pb ? _vm.props.pb : '0'}`,attrs:{"id":`${_vm.props.target}`,"color":_vm.props.color_background}},[(_vm.props)?_c('v-container',{staticClass:"container-padding",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pa-0",style:(`margin-right: ${
          _vm.$vuetify.breakpoint.smAndDown ? '0' : '-80px'
        }; margin-top: ${_vm.$vuetify.breakpoint.smAndDown ? '0' : '65px'}
        `),attrs:{"cols":"12","md":"6"}},[_c('v-sheet',{staticClass:"rounded-lg",attrs:{"elevation":1}},[(_vm.props.img_href)?_c('v-img',{style:(`position: relative; z-index: 2;border-radius: 5px;`),attrs:{"width":"100%","src":_vm.props.img_href}}):_vm._e()],1)],1),_c('v-col',{staticClass:"px-0",staticStyle:{"z-index":"1"},attrs:{"cols":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('v-sheet',{staticClass:"rounded-lg px-4 py-2",attrs:{"color":"#f2f2f2"}},[(_vm.props.headline_img)?_c('v-img',{staticClass:"mb-10",attrs:{"max-height":"60px","src":_vm.props.headline_img,"contain":""}}):_vm._e(),(_vm.props.headline && _vm.props.headline[_vm.language])?_c('div',{domProps:{"innerHTML":_vm._s(_vm.props.headline[_vm.language])}}):_vm._e(),(_vm.props.text && _vm.props.text[_vm.language])?_c('div',{style:(`margin-left: ${
              _vm.$vuetify.breakpoint.smAndDown ? '0' : '90px'
            };`),domProps:{"innerHTML":_vm._s(_vm.props.text[_vm.language])}}):_vm._e(),(_vm.props.button_text && _vm.props.button_text[_vm.language])?_c('v-row',[_c('v-col',{staticClass:"px-6",attrs:{"align":_vm.$vuetify.breakpoint.mdAndUp ? '' : 'center'}},[_c('a',{attrs:{"href":_vm.props.button_href}},[_c('v-btn',{staticClass:"px-4 py-5",attrs:{"color":"secondary"},domProps:{"innerHTML":_vm._s(_vm.props.button_text[_vm.language])}})],1)])],1):_vm._e()],1)],1)],1),(_vm.edit)?_c('v-dialog',{attrs:{"persistent":"","fullscreen":""},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 black white--text"},[_vm._v(" Edit Component ")]),_c('v-card-text',{staticClass:"pt-5"},[_c('Core',{attrs:{"name":"Core","editProps":_vm.editProps},on:{"emitProps":_vm.setPreviewProps,"save":_vm.save,"cancel":_vm.cancel}}),_c('h2',{staticClass:"my-2 text-h4 black--text"},[_vm._v("Preview:")]),(_vm.previewProps)?_c('CompTextImageOverlap',{attrs:{"name":"CompTextImageOverlap","props":_vm.previewProps}}):_vm._e()],1),_c('v-divider')],1)],1):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }